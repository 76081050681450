<template>
  <div>
    <div class="page-title">客户信息管理</div>
    <!-- 搜索、按钮 -->
    <div style="float:left;margin-top: 1rem; width: 100%">
      <el-input
        clearable
        placeholder="请输入关键词"
        v-model="keywords"
        style="width:300px"
        @keydown.enter.native="getCustom_"
      ></el-input>
      <el-button type="primary" plain @click="getCustom_">搜索客户</el-button>
      <el-button type="danger" plain @click="ShowInLayer">导入客户资料</el-button>
      <el-button type="warning" plain @click="ClickOutput">导出客户资料</el-button>
      <el-button type="success" plain @click="ShowAddLayer">新建客户资料</el-button>
    </div>
    <!-- 表格 -->
    <div style="margin-top: 1rem; float:left; width: 100%;">
      <el-table
        class="tb-edit"
        :data="customData"
        border
        stripe
        @row-dblclick="dbClick"
      >
        <el-table-column prop="收件人" label="收件人" width="120"></el-table-column>
        <el-table-column prop="电话" label="电话" width="200"></el-table-column>
        <el-table-column prop="公司" label="公司"></el-table-column>
        <el-table-column prop="地址" label="地址"></el-table-column>
        <el-table-column prop="备注" label="备注"></el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div style="width: 100%;float:left;margin-top: 1rem;">
      <el-pagination
        @current-change="handlePageChange"
        :current-page="whichPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 导入层 -->
    <el-dialog
      title="导入客户资料"
      :visible.sync="inLayer"
      width="420px"
      :close-on-click-modal="false"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="uploadUrl"
        :on-success="function(res){return uploadOver(res)}"
        :before-upload="function(file){ return beforeUpload(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="inLayer = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 新建层 -->
    <el-dialog
      title="新建客户资料"
      :visible.sync="addLayer"
      width="580px"
      :close-on-click-modal="false"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <el-form v-model="addCustomForm">
        <el-form-item label-width="120px" label="* 收件人">
          <el-input v-model="addCustomForm.收件人"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="* 电话">
          <el-input v-model="addCustomForm.电话"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="* 公司">
          <el-input v-model="addCustomForm.公司"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="* 地址">
          <el-input v-model="addCustomForm.地址"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="备注">
          <el-input v-model="addCustomForm.备注" type="textarea" row="5"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doCustomAdd" type="success">添 加</el-button>
        <el-button @click="addLayer = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 修改层 -->
    <el-dialog
      title="修改客户资料"
      :visible.sync="editLayer"
      width="580px"
      :close-on-click-modal="false"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <el-form v-model="editCustomForm">
        <el-form-item label-width="120px" label="* 收件人">
          <el-input v-model="editCustomForm.收件人"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="* 电话">
          <el-input v-model="editCustomForm.电话"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="* 公司">
          <el-input v-model="editCustomForm.公司"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="* 地址">
          <el-input v-model="editCustomForm.地址"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="备注">
          <el-input v-model="editCustomForm.备注" type="textarea" row="5"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doCustomEdit" type="success" plain>修 改</el-button>
        <el-button @click="editLayer = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getCustom, addCustomInfo, editCustomInfo} from '../http/api'
import {baseUrl} from '../configs/squareConfig'
import {Loading} from 'element-ui'

let loading
export default {
  name: "Custom",
  data() {
    return {
      token: this.$cookies.get('token'),
      customData: [],
      inLayer: false,
      addLayer: false,
      editLayer: false,
      uploadUrl: baseUrl + '/custom-upload',
      keywords: '',
      addCustomForm: {},
      editCustomForm: {},
      whichPage: 1,
      pageSize: 100,
      count: 0,
    }
  },
  methods: {
    getCustom_() {
      const token = this.token
      const keywords = this.keywords
      getCustom({
        token,
        keywords,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.customData = res.data.rs
            this.whichPage = res.data.page + 1
            this.pageSize = res.data.pageSize
            this.count = res.data.count
          }
        })
        .catch(err => console.log(err))
    },
    uploadOver(res) {
      loading.close()
      if (res.code === 'OK') {
        this.$notify({
          type: 'success',
          message: '成功导入 ' + res.countIns + ' 条'
        })
        this.inLayer = false
        this.getCustom_()
      } else {
        this.$notify({
          type: 'error',
          message: res.msg,
        })
      }
    },
    beforeUpload(file) {
      loading = Loading.service({
        fullscreen: true
      })
    },
    ShowInLayer() {
      this.inLayer = true
    },
    ClickOutput() {
    },
    ClearAll() {
    },
    handleEdit(index, row) {
      this.editLayer = true
      this.editCustomForm = JSON.parse(JSON.stringify(row))
    },
    handleDelete(index, row) {
      this.$message({
        type: 'warning',
        message: '暂不支持删除'
      })
    },
    dbClick(row) {
      this.editLayer = true
      this.editCustomForm = JSON.parse(JSON.stringify(row))
    },
    ShowAddLayer() {
      this.addLayer = true
    },
    doCustomAdd() {
      const addCustomForm = this.addCustomForm
      const token = this.token
      if (!addCustomForm.收件人 || !addCustomForm.电话 || !addCustomForm.公司 || !addCustomForm.地址) {
        this.$message({
          type: 'warning',
          message: '请输入完整再提交'
        })
        return
      }
      addCustomInfo({token, addCustomForm})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '添加成功',
            })
            this.getCustom_()
            this.addLayer = false
          }
        })
        .catch(err => console.log(err))
    },
    doCustomEdit() {
      const editCustomForm = this.editCustomForm
      const token = this.token
      if (!editCustomForm.收件人 || !editCustomForm.电话 || !editCustomForm.公司 || !editCustomForm.地址) {
        this.$message({
          type: 'warning',
          message: '请输入完整再提交'
        })
        return
      }
      editCustomInfo({token, editCustomForm})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '修改成功',
            })
            this.getCustom_()
            this.editLayer = false
          }
        })
        .catch(err => console.log(err))
    },
    handlePageChange() {
    },
  },
  activated() {
    this.getCustom_()
  },
}
</script>

<style scoped>

</style>
